import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Grid, Button, Hidden } from "@material-ui/core"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Intro from "../components/website/Intro"
import BlueSection from "../components/website/BlueSection"
import Parallax from "../components/website/Parallax"
import Locations from "../components/locations/index"

import Footer from "../images/brokers/brokers-cta.jpg"
import Partner from "../images/brokers/why-partner-with-us.jpg"
import blueBG from "../images/brokers/brokers-background.svg"

import { Clock, ChartBar, ArrowsUp } from "tabler-icons-react"

export default function Brokers({ data }) {
  return (
    <Layout>
      <SEO title="Brokers" />
      {data.allStrapiBrokers.edges.map(({ node }) => (
        <>
          <Intro
            key={node.brokers_intro.id}
            title={node.brokers_intro.intro_title}
            text={node.brokers_intro.intro_description}
            img={node.brokers_intro.Intro_hero.publicURL}
            buttonLink="https://brokerportal.marathonmortgage.ca/mmc-portal/login"
            buttonTitle="Broker Portal"
          />
          <BlueSection
            key={node.brokers_bluesection.id}
            title={node.brokers_bluesection.Bluesection_title}
            description={node.brokers_bluesection.bluesection_title_description}
            order="right"
            intro={true}
            background={blueBG}
          />
          <Container>
            <Grid container className="pb-40 why-partner">
              <Grid item md={6} sm={12}>
                <img src={Partner} />
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                className="flex justify-center flex-col"
              >
                <h4 className="text-orange">Why partner with Marathon?</h4>
                <p>
                  Join our growing portfolio of licensed brokers and agents
                  across the country. Become a valued partner today – you’ll be
                  glad you did.
                </p>
                <ul className="p-0 partner">
                  <li className="list-none">
                    <h6 className="text-blue">
                      <Clock size={48} strokeWidth={1} color={"#2B6CB0"} />{" "}
                      Exceptional Service &amp; Turnaround Time
                    </h6>
                  </li>
                  <li className="list-none">
                    <h6 className="text-blue">
                      <ChartBar size={48} strokeWidth={1} color={"#2B6CB0"} />{" "}
                      Competitive Rates
                    </h6>
                  </li>
                  <li className="list-none">
                    <h6 className="text-blue">
                      <ArrowsUp size={48} strokeWidth={1} color={"#2B6CB0"} />{" "}
                      Aggressive Compensation
                    </h6>
                  </li>
                </ul>
                <Link to="/contact" className="blue-button mt-10">
                  <Button>Contact Us</Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
          <Parallax
            title="View mortgage rates &amp; information"
            link="/rates#table"
            button="view rates"
            order="right"
            image={Footer}
          />
          <BlueSection
            key={node.brokers_bluesection_2.id}
            title={node.brokers_bluesection_2.Bluesection_2_title}
            smalltitle={node.brokers_bluesection_2.bluesection_title_small}
            description={node.brokers_bluesection_2.Bluesection_2_decription}
            order="right"
            component={<Locations />}
          />
        </>
      ))}
      <Hidden smDown>
        <div className="calc-offset" />
      </Hidden>
    </Layout>
  )
}

export const query = graphql`
  query Brokers {
    allStrapiBrokers {
      edges {
        node {
          brokers_intro {
            id
            intro_title
            intro_color
            intro_description
            Intro_hero {
              publicURL
            }
          }
          brokers_bluesection {
            id
            Bluesection_title
            bluesection_title_description
          }
          brokers_bluesection_2 {
            id
            Bluesection_2_title
            bluesection_title_small
            Bluesection_2_decription
          }
        }
      }
    }
  }
`
